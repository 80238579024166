import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { AcquisitionInformationInput, Currency } from '~/graphql/member/types';
import { ExpireDateMethod, NFT_SHOP_TYPE, Unit } from '~/types/my-shop';

export interface IMyShopFormWrapper {
  siteSetting?: {
    ogp?: string | null;
    logo?: string | null;
    meta?: string | null;
    name?: string | null;
    showPriceUsd?: boolean;
    category?: NFT_SHOP_TYPE;
    favicon?: string | null;
    siteName?: string | null;
    ogpFile?: FileList | null;
    logoFile?: FileList | null;
    domainName?: string | null;
    faviconFile?: FileList | null;
    metaDescription?: string | null;
  } | null;
  shopInformation?: {
    policy?: string | null;
    terms?: string | null;
    information?: string | null;
  } | null;
  paymentMethod?: {
    baseCurrency?: Currency | null;
  } | null;
  nftActivationSettings?: {
    expireUnit?: Unit;
    expireValue?: string;
    expireDate?: Dayjs | null;
    expireMethod?: ExpireDateMethod;
    acquisitionInformation?: AcquisitionInformationInput[];
  } | null;
}

export interface MyShopFormWrapperContextValue {
  step: number;
  formData: IMyShopFormWrapper;
  handleClear: () => void;
  handleNextStep: (length: number) => void;
  handlePreviousStep: () => void;
  setFormData: Dispatch<SetStateAction<IMyShopFormWrapper>>;
}

const MyShopFormWrapperContext = createContext<MyShopFormWrapperContextValue>({} as any);
export const useMyShopFormWrapper = () => useContext(MyShopFormWrapperContext);

export const initData: IMyShopFormWrapper = {
  siteSetting: {
    ogp: '',
    logo: '',
    meta: '',
    name: '',
    favicon: '',
    siteName: '',
    domainName: '',
    ogpFile: undefined,
    logoFile: undefined,
    showPriceUsd: false,
    metaDescription: '',
    faviconFile: undefined,
    category: NFT_SHOP_TYPE.PRE_MINT,
  },
  shopInformation: {
    policy: '',
    terms: '',
    information: '',
  },
  paymentMethod: {
    baseCurrency: Currency.Jpy,
  },
  nftActivationSettings: {
    acquisitionInformation: [],
    expireMethod: ExpireDateMethod.SPECIFY_DATE,
    expireDate: null,
    expireValue: '1',
    expireUnit: Unit.MONTH,
  },
};

const MyShopFormWrapper: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<IMyShopFormWrapper>(initData);

  useEffect(() => {
    return () => {
      handleClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleNextStep = useCallback(
    (length: number) => {
      if (step < length - 1) {
        setStep(step + 1);
      }
    },
    [step]
  );

  const handlePreviousStep = useCallback(() => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(AppRouteEnum.MyShop);
    }
  }, [step, navigate]);

  const handleClear = () => {
    setStep(0);
    setFormData(initData);
  };

  return (
    <MyShopFormWrapperContext.Provider
      value={{
        step,
        formData,
        setFormData,
        handleClear,
        handleNextStep,
        handlePreviousStep,
      }}
    >
      {children}
    </MyShopFormWrapperContext.Provider>
  );
};

export default MyShopFormWrapper;
