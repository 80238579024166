import { ChangeEvent, FC, forwardRef, KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { jaJP as jaJPDatePicker, DatePicker, LocalizationProvider, DatePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { ControllerRenderProps, Path, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import AcquisitionInformation from './AcquisitionInformation';

import { NumberStringTextField } from '~/components/NumberStringTextField';
import WrapperTextField from '~/components/WrapperTextField';
import { DATE_TIME_FORMAT } from '~/constants/common';
import { IMyShopFormWrapper, initData, useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { MemberSiteFormEnum } from '~/enum/pages/my-shop';
import { GetMyShopDocument, useUpdateMyShopMutation } from '~/graphql/member/types';
import { ExpireDateMethod, Unit } from '~/types/my-shop';
import { isDeepEqual } from '~/utils/object.utils';
import { getErrorText } from '~/utils/yup.util';

const MAX_NUMBER = 99999999999999999999;

interface RegistrationConditionProps {
  isCreate?: boolean;
  isSubmitting?: boolean;
  onCreate?: (data: IMyShopFormWrapper) => Promise<void>;
}

const useStyles = makeStyles<{ isCreate?: boolean }>()((_, { isCreate }) => ({
  wrapper: {
    '.MuiPaper-root': {
      boxShadow: 'none',
      overflow: 'hidden',
      borderRadius: '8px',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root > .MuiCardHeader-root': {
      minHeight: '52px',
      padding: '0px 16px',
      borderBottom: isCreate ? 'none' : '1px solid #D7D7D7',
      '.MuiCardHeader-action': {
        alignSelf: 'center',
      },
    },
    '.MuiCardContent-root': {
      padding: '16px 16px 0',
      display: 'flex',
      '.cardContent': {
        width: '100%',
        '.MuiTypography-h4': {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '32px',
          letterSpacing: '0.25px',
        },
        '.MuiTypography-caption': {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          marginBottom: '16px',
          whiteSpace: 'pre-wrap',
          display: 'inline-block',
        },
        '.wrapperInput': {
          padding: '12px 16px',
          '.MuiTextField-root': {
            '.MuiInputLabel-root': {
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '16px',
              color: '#00000099',
              transform: 'initial',
              letterSpacing: '1.5px',
            },
            '.MuiInput-root .MuiInput-input': {
              padding: 0,
            },
          },
        },
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: isCreate ? 'space-between' : 'flex-end',
    },
  },
  informationTitle: {
    display: 'flex',
  },
  errorText: {
    fontSize: '12px',
    margin: '3px 14px 0',
  },
}));

const schema = yup.object({
  acquisitionInformation: yup
    .array()
    .of(
      yup.object({
        id: yup.string().nullable(),
        type: yup.string().nullable(),
        required: yup.boolean().nullable(),
        displayName: yup
          .string()
          .nullable()
          .test('is-required', 'form_validation.this_field_cannot_be_empty', (value) => !!value),
      })
    )
    .required(),
  expireMethod: yup.mixed<ExpireDateMethod>().oneOf(Object.values(ExpireDateMethod)).required(),
  expireDate: yup
    .mixed<Dayjs>()
    .nullable()
    .when('expireMethod', {
      is: ExpireDateMethod.SPECIFY_DATE,
      then: (schema) => schema.required(),
    }),
  expireValue: yup
    .string()
    .minNumber(1)
    .maxNumber(MAX_NUMBER)
    .test({
      message: 'form_validation.can_only_contain_integer',
      test: (value) => /^[0-9]*$/.test(value?.toString() || ''),
    })
    .when('expireMethod', {
      is: ExpireDateMethod.SPECIFY_HOW_LONG,
      then: (schema) => schema.required(),
    }),
  expireUnit: yup
    .mixed<Unit>()
    .oneOf(Object.values(Unit))
    .when('expireMethod', {
      is: ExpireDateMethod.SPECIFY_HOW_LONG,
      then: (schema) => schema.required(),
    }),
});

export interface FormRegistrationConditionValues extends yup.InferType<typeof schema> {}

const initExpireMethods = (t: TFunction) => ({
  [ExpireDateMethod.SPECIFY_DATE]: {
    value: ExpireDateMethod.SPECIFY_DATE,
    label: t('member_site.specify_date'),
    description: t('member_site.specify_date_description'),
  },
  [ExpireDateMethod.SPECIFY_HOW_LONG]: {
    value: ExpireDateMethod.SPECIFY_HOW_LONG,
    label: t('member_site.specify_how_long'),
    description: t('member_site.specify_how_long_description'),
  },
  [ExpireDateMethod.NOT_EXPIRE]: {
    value: ExpireDateMethod.NOT_EXPIRE,
    label: t('member_site.not_expire'),
    description: t('member_site.not_expire_description'),
  },
});

const initUnits = (t: TFunction) => ({
  [Unit.DAY]: {
    value: Unit.DAY,
    label: t('day'),
  },
  [Unit.MONTH]: {
    value: Unit.MONTH,
    label: t('month'),
  },
  [Unit.YEAR]: {
    value: Unit.YEAR,
    label: t('year'),
  },
});

const WrapperDatePicker = forwardRef<any, DatePickerProps<any>>((props, ref) => {
  return <DatePicker inputRef={ref} {...props} />;
});

const RegistrationCondition: FC<RegistrationConditionProps> = ({ isCreate, isSubmitting, onCreate }) => {
  const { editingAt, handleEditingAt } = useShopDetail();
  const { formData, setFormData, handlePreviousStep } = useMyShopFormWrapper();

  const [invalidDate, setInvalidDate] = useState(false);

  const isEditing = editingAt === MemberSiteFormEnum.REGISTRATION;

  const { id: shopId } = useParams();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles({ isCreate });

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const defaultValues = useMemo(
    () => {
      const nftActivationSettings = formData?.nftActivationSettings;
      const _acquisitionInformation = nftActivationSettings?.acquisitionInformation;
      return {
        expireDate: nftActivationSettings?.expireDate,
        expireUnit: nftActivationSettings?.expireUnit,
        expireValue: nftActivationSettings?.expireValue,
        acquisitionInformation: _acquisitionInformation,
        expireMethod: nftActivationSettings?.expireMethod,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData?.nftActivationSettings]
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormRegistrationConditionValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });

  const handleChangeToEdit = () => {
    handleEditingAt(MemberSiteFormEnum.REGISTRATION);
  };
  const handleCancel = () => {
    if (isCreate) {
      handlePreviousStep();
    } else {
      reset(defaultValues);
      handleEditingAt(undefined);
    }
  };

  const formValues = useWatch({ control });

  const isDirty = !isDeepEqual(defaultValues, formValues);

  const onSubmit = async (data: FormRegistrationConditionValues) => {
    const finalizeData = {
      ...formData,
      nftActivationSettings: data,
    };
    setFormData(finalizeData);
    if (isCreate) {
      if (onCreate) {
        onCreate(finalizeData);
      }
    } else {
      try {
        await updateMyShop({
          variables: {
            input: {
              uuid: shopId ?? '',
              nftActivationSettings: data,
            },
          },
        });
        handleEditingAt(undefined);
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    }
  };

  const expireMethodOptions = useMemo(
    () =>
      Object.values(initExpireMethods(t)).map((method) => (
        <MenuItem key={method.value} value={method.value}>
          {method.label}
        </MenuItem>
      )),
    [t]
  );

  const unitOptions = useMemo(
    () =>
      Object.values(initUnits(t)).map((unit) => (
        <MenuItem key={unit.value} value={unit.value}>
          {unit.label}
        </MenuItem>
      )),
    [t]
  );

  const addsSpecificOptions = useCallback(() => {
    switch (formValues.expireMethod) {
      case ExpireDateMethod.SPECIFY_DATE:
        return (
          <WrapperTextField
            control={control}
            name="expireDate"
            isEditing={isCreate || isEditing}
            label={t('member_site.expire_date')}
            formatValue={(field) => {
              return dayjs(field.value as string).format(t('date_format'));
            }}
            render={(field) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language}
                localeText={jaJPDatePicker.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <WrapperDatePicker
                  onError={(reason) => {
                    if (reason === 'invalidDate') {
                      setInvalidDate(true);
                    } else {
                      setInvalidDate(false);
                    }
                  }}
                  sx={(theme) => ({
                    width: '100%',
                    [theme.breakpoints.up('md')]: {
                      width: '50%',
                    },
                  })}
                  format={DATE_TIME_FORMAT}
                  label={t('member_site.expire_date')}
                  slots={{ textField: TextField }}
                  slotProps={{
                    textField: { error: !!errors.expireDate, helperText: t(errors.expireDate?.message || '') },
                  }}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />
        );
      case ExpireDateMethod.SPECIFY_HOW_LONG:
        return (
          <Box display="flex" gap="16px">
            <WrapperTextField
              label={t('number')}
              control={control}
              name="expireValue"
              isEditing={isCreate || isEditing}
              render={(field) => (
                <NumberStringTextField
                  required
                  sx={{ flex: 1 }}
                  variant="outlined"
                  placeholder={t('number')}
                  error={!!errors.expireValue?.message}
                  helperText={getErrorText(errors.expireValue?.message, t, 'number')}
                  {...field}
                />
              )}
            />
            <WrapperTextField
              label={t('unit')}
              name="expireUnit"
              control={control}
              isEditing={isCreate || isEditing}
              formatValue={(field) => initUnits(t)[field.value as Unit].label}
              render={(field) => (
                <TextField
                  select
                  required
                  sx={{ flex: 1 }}
                  label={t('unit')}
                  error={!!errors.expireUnit?.message}
                  helperText={t(errors.expireUnit?.message as any)}
                  {...field}
                >
                  {unitOptions}
                </TextField>
              )}
            />
          </Box>
        );
      case ExpireDateMethod.NOT_EXPIRE:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, control, formValues.expireMethod, t, isEditing]);

  const handleChangeExpireMethod =
    (field: ControllerRenderProps<FormRegistrationConditionValues, Path<FormRegistrationConditionValues>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event);
      if (invalidDate) {
        setInvalidDate(false);
      }
      reset({
        ...initData.nftActivationSettings,
        expireMethod: event.target.value as ExpireDateMethod,
        acquisitionInformation: formValues.acquisitionInformation,
      });
    };

  useEffect(() => {
    return () => {
      if (!!handleEditingAt) {
        handleEditingAt(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          title={t('member_site.registration_condition')}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          <Box className="cardContent">
            <Typography variant="caption">{t('member_site.nft_activation_settings_description')}</Typography>
            <Box className={classes.informationTitle}>
              <Typography variant="h4">{t('member_site.acquisition_information')}</Typography>
            </Box>
            <Box marginBottom="16px">
              <AcquisitionInformation errors={errors} control={control} disabled={!(isCreate || isEditing)} />
            </Box>
            <Typography variant="h4">{t('member_site.expire_date')}</Typography>
            <Typography variant="caption">{t('member_site.expire_date_description')}</Typography>
            <WrapperTextField
              isEditing={isCreate || isEditing}
              label={t('method')}
              name="expireMethod"
              control={control}
              formatValue={(field) => initExpireMethods(t)[field.value as ExpireDateMethod].label}
              render={(field) => (
                <TextField
                  select
                  required
                  fullWidth
                  label={t('method')}
                  error={!!errors.expireMethod?.message}
                  helperText={t(errors.expireMethod?.message as any)}
                  {...field}
                  onChange={handleChangeExpireMethod(field)}
                >
                  {expireMethodOptions}
                </TextField>
              )}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="caption" marginTop="8px">
                {initExpireMethods(t)[formValues.expireMethod || ExpireDateMethod.SPECIFY_DATE].description}
              </Typography>
              {addsSpecificOptions()}
            </Box>
            {invalidDate && (
              <Typography sx={{ color: '#DF0303', fontSize: '12px', fontWeight: 400 }}>
                {t('form_validation.this_field_cannot_be_empty')}
              </Typography>
            )}
          </Box>
        </CardContent>
        <CardActions>
          {isCreate || isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting}>
                {t(isCreate ? 'back' : 'cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={(!isCreate && !isDirty) || isSubmitting || invalidDate}
                onClick={handleSubmit(onSubmit)}
                endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
              >
                {t(isCreate ? 'create' : 'save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default RegistrationCondition;
